.calendar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
  margin-left: 10px;
}

.calendar-icon {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

/* PARTIALLY FOR DELETE BUTTON */

.calendar-button:hover,
.delete-button:hover {
  transform: scale(1.05);
}

.calendar-button:active,
.delete-button:active {
  transform: scale(0.95);
}

/* .calendar-button:focus {
outline: 3px solid #FFC107;
outline-offset: 2px;
} */

/* ALL FOR DELETE BUTTON */

.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
  margin-left: 10px;
}

.delete-icon {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}
