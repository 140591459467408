body {
  font-family: Georgia, "Times New Roman", Times, serif, sans-serif;
  margin: 10px;
  padding: 0;
  background-color: #f5f5f5;
}

body h1 {
  font-family: "Courier New", Courier, monospace, sans-serif;
  font-size: 40px;
  font-weight: 200px;
  margin-top: 1vh;
  margin-bottom: 0;
}

body h2 {
  font-family: "Courier New", Courier, monospace, sans-serif;
  font-size: 20px;
  font-weight: 200px;
}

body p {
  margin-top: 1vh;
}

body h1,
p {
  text-align: center;
}

.app {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.scheduler-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.classes-container {
  display: flex; /* Use flexbox to arrange the classes side by side */
  justify-content: space-between; /* Distribute space between the two sections */
  gap: 20px; /* Add some space between the two sections */
}

.class-item {
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 3px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.class-item:hover {
  background-color: #f0f0f0; /* Highlight when hovering */
}
.class-item.selected:hover {
  background-color: #d1e7dd; /* Highlight when hovering */
}

.class-item.selected {
  background-color: #d1e7dd; /* Highlight selected class */
  border-color: #18e589;
}

.selected-classes ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
}

.selected-classes li {
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 3px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100%;
}

/* Scrollable container for Available and Selected Classes */

.available-classes {
  width: 48%;
  padding: 10px;
}

.selected-classes {
  width: 56%;
  padding: 10px;
}

h2 {
  margin: auto;
  text-align: center;
}

.scrollable-list {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  height: 600px; /* Adjust height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
}

.class-item:hover {
  background-color: #e9e9e9;
}

.selected {
  background-color: #d3ffd3; /* Light green for selected class */
}

/* Style for the subject filter dropdown */
.subject-filter {
  font-size: 12px; /* Smaller font size */
  height: 30px; /* Make the dropdown more compact */
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Style for the dropdown options */
.subject-filter option {
  padding: 5px; /* Reduce padding of options */
  font-size: 12px; /* Smaller font for options */
}

.filter-dropdown label {
  font-weight: bold;
  margin-right: 10px;
}

.filter-dropdown select {
  padding: 5px;
  font-size: 16px;
}

.filter-dropdown label {
  font-weight: bold;
  margin-right: 10px;
}

.filters {
  display: flex;
  justify-content: space-between;
}

.clear-button:hover {
  color: red;
}

#full-selected-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}
